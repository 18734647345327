var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ce-newslist row g-5"},[_vm._l((_vm.data.list),function(singleNews,key){return _c('div',{key:key,staticClass:"col-md-6"},[_c('div',{staticClass:"news-content"},[_c('div',{staticClass:"news-content--item"},[_c('nav-link',{attrs:{"to":singleNews.slug,"tag":"a"}},[_c('div',{staticClass:"news-content--item-img"},[(_vm.getFirstImageSrc(key))?_c('nuxt-img',{staticClass:"lazyload",attrs:{"src":_vm.getFirstImageSrc(key),"alt":_vm.getFirstImageProperties(key).alternative,"format":"webp","sizes":{
								sm: '30vw',
								md: '50vw',
								lg: '50vw',
								xxl: '500px',
							},"preload":""}}):_vm._e()],1),_vm._v(" "),(singleNews.categories.length > 0)?_c('div',{staticClass:"news-content--item-categories"},_vm._l((singleNews.categories),function(category,index){return _c('span',{key:index,staticClass:"categories-title"},[_c('span',[_vm._v(_vm._s(category.title))]),_vm._v(" "),(
									index + 1 < singleNews.categories.length
								)?_c('span',[_vm._v(",\n\t\t\t\t\t\t\t")]):_vm._e()])}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"news-content--item-body"},[(singleNews.teaser)?_c('p',{staticClass:"news-content--itemtext"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm._f("truncateText")(singleNews.teaser,100))+"\n\t\t\t\t\t\t")]):_vm._e()])])],1)])])}),_vm._v(" "),_c('nav',{attrs:{"aria-label":"Page News List Pagination"}},[(_vm.data.pagination.numberOfPages === 2)?_c('ul',{staticClass:"pagination justify-content-center"},[_c('li',{staticClass:"page-item page-item--preview"},[_c('nav-link',{staticClass:"page-link",attrs:{"to":"#","tabindex":"-1","aria-disabled":"true"}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'chevron-left']}})],1)],1),_vm._v(" "),_vm._l((_vm.data.pagination.numberOfPages),function(page){return _c('li',{key:page,staticClass:"page-item",class:{ active: _vm.isCurrent(page) }},[_c('nav-link',{staticClass:"page-link",class:{ active: _vm.isCurrent(page) },attrs:{"to":"#"}},[_vm._v("\n\t\t\t\t\t"+_vm._s(page)+"\n\t\t\t\t")])],1)}),_vm._v(" "),_c('li',{staticClass:"page-item page-item--next"},[_c('nav-link',{staticClass:"page-link",attrs:{"to":"#"}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'chevron-right']}})],1)],1)],2):_vm._e()])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }