<template>
	<div class="pisubsidary w-100">

			<HeadvisualSubsidiary :data="data" />

			<div v-if="breadcrumbs" class="breadcrumbs d-none d-lg-block">
				<breadcrumbs :links="breadcrumbs" />
			</div>

			<ContactSubsidiary :data="data"/>

			<ce-renderer
				:content="data.content.service"
			/>

			<ce-renderer
				:content="data.content.elements"
			/>

			<ce-renderer
				:content="data.content.jobs"
			/>

			<ce-renderer
				:content="data.content.seo"
			/>

			<ce-renderer
				v-if="data.content.reference.appearance == 1"
				:content="data.content.reference"
			/>

	</div>
</template>

<script>
import { mapState } from 'vuex';
import HeadvisualSubsidiary from '~/components/organisms/subsidiary/elements/HeadvisualSubsidiary';
import Breadcrumbs from '~/components/molecules/navigation/Breadcrumbs'
import ContactSubsidiary from '~/components/organisms/subsidiary/elements/ContactSubsidiary';

export default {
	components: {
		HeadvisualSubsidiary,
		Breadcrumbs,
		ContactSubsidiary
	},
	props: {
		locationLatitude: {
			type: String
		},
		data: {
			type: Object,
			required: false,
			default: () => {},
		},
	},
	computed: {
		...mapState({
			breadcrumbs: (state) => {
				return state.typo3.page.page ? state.typo3.page.page.breadcrumbs : [];
			}
		})
	}
}
</script>
