<template>
	<div class="ce-newslist row g-5">
		<div v-for="(singleNews, key) in data.list" :key="key" class="col-md-6">
			<div class="news-content">
				<div class="news-content--item">
					<nav-link :to="singleNews.slug" tag="a">
						<div class="news-content--item-img">
							<nuxt-img
								v-if="getFirstImageSrc(key)"
								:src="getFirstImageSrc(key)"
								:alt="getFirstImageProperties(key).alternative"
								format="webp"
								:sizes="{
									sm: '30vw',
									md: '50vw',
									lg: '50vw',
									xxl: '500px',
								}"
								preload
								class="lazyload"
							/>
						</div>

						<div
							v-if="singleNews.categories.length > 0"
							class="news-content--item-categories"
						>
							<span
								v-for="(
									category, index
								) in singleNews.categories"
								:key="index"
								class="categories-title"
							>
								<span>{{ category.title }}</span>
								<span
									v-if="
										index + 1 < singleNews.categories.length
									"
									>,
								</span>
							</span>
						</div>

						<div class="news-content--item-body">
							<p
								v-if="singleNews.teaser"
								class="news-content--itemtext"
							>
								{{ singleNews.teaser | truncateText(100) }}
							</p>
						</div>
					</nav-link>
				</div>
			</div>
		</div>

		<nav aria-label="Page News List Pagination">
			<ul
				class="pagination justify-content-center"
				v-if="data.pagination.numberOfPages === 2"
			>
				<li class="page-item page-item--preview">
					<nav-link
						to="#"
						class="page-link"
						tabindex="-1"
						aria-disabled="true"
					>
						<font-awesome-icon :icon="['fal', 'chevron-left']" />
					</nav-link>
				</li>
				<li
					class="page-item"
					v-for="page in data.pagination.numberOfPages"
					:key="page"
					:class="{ active: isCurrent(page) }"
				>
					<nav-link
						to="#"
						class="page-link"
						:class="{ active: isCurrent(page) }"
					>
						{{ page }}
					</nav-link>
				</li>
				<li class="page-item page-item--next">
					<nav-link to="#" class="page-link">
						<font-awesome-icon :icon="['fal', 'chevron-right']" />
					</nav-link>
				</li>
			</ul>
		</nav>
	</div>
</template>

<script>
import NewsListMixins from './mixins/NewsListMixins'
export default {
	name: 'NewsList',
	mixins: [NewsListMixins],
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
}
</script>

<style lang="scss">
@import '~@/assets/scss/common';
$pagination-active-preview-next-color-bg: color(primary, base) !default;
$pagination-active-preview-next-color: color(white) !default;
$pagination-page-link-witdh: 40px !default;
$news-content--item-body-border: $default--border-color !default;

$news-content-background-color: color(white) !default;
$news-content-content--item-body-padding: rem(30) !default;
$news-content-content--item-body-height: 50px !default;
$news-content-content--item-category-width: 100px !default;
$news-content-content--item-category-width-lg: 70px !default;
$news-content-content--item-img: 300px !default;
$news-content-content--item-img-lg: 400px !default;
$news-content-content--item-category-background: rgba(
	93,
	186,
	229,
	0.91
) !default;

.news-content {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	height: 100%;
	background: $news-content-background-color;

	border-top-right-radius: 30px;
	border-top-left-radius: 30px;

	.news-content--item {
		display: flex;
		position: relative;
	}

	.news-content--item-img {
		height: $news-content-content--item-img;

		@include media-breakpoint-up(xl) {
			height: $news-content-content--item-img-lg;
		}

		img {
			display: block;
			width: 100%;
			height: 100%;
			margin: 0 auto;

			object-fit: cover;
			font-family: 'object-fit: cover;';

			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	.news-content--item-body {
		padding: $news-content-content--item-body-padding;
		background: $news-content-background-color;
		min-height: $news-content-content--item-body-height;
		border-bottom-right-radius: 30px;
		border-bottom-left-radius: 30px;

		p {
			margin-bottom: 0;
		}
	}

	.news-content--item-categories {
		position: absolute;
		right: 5%;
		margin-top: -80px;
		display: grid;
		text-align: center;
		@include responsive-type(0, 0.5);
		z-index: 2;

		width: $news-content-content--item-category-width;
		height: $news-content-content--item-category-width;
		background: $news-content-content--item-category-background;
		color: $news-content-background-color;
		border-radius: 50%;

		@include media-breakpoint-between(md, lg) {
			width: $news-content-content--item-category-width-lg;
			height: $news-content-content--item-category-width-lg;
			margin-top: -50px;
		}
	}

	.news-content--item-categories > * {
		align-self: center;
	}
}

.news-content--item {
	.news-content--item-body {
		border: 1px solid $news-content--item-body-border;
		.news-content--itemtext {
			flex: 1 0 auto;
		}
	}
}

.highlight-box--image .news-content--item {
	.news-content--item-body {
		border: none;
	}
}
.pagination {
	margin-bottom: $news-content-content--item-category-width;
}
.page-item {
	&--preview,
	&--next {
		.page-link {
			background: $pagination-active-preview-next-color-bg;
			color: $pagination-active-preview-next-color;
		}
	}

	.page-link {
		text-align: center;
		height: $pagination-page-link-witdh;
		width: $pagination-page-link-witdh;
	}
}
</style>
