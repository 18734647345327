<template>
	<div class="highlight-box--image fullwidth-container pt-5 pb-5">
		<div class="highlight-box fullwidth-container">
			<div class="page--wrapper">
				<div class="container">
					<div class="multi-columns">
						<div class="row">
							<div
								class="col-xs-12 col-lg-6 col-xl-6 col-xxl-6 d-flex align-items-center"
							>
								<div class="mb-3 locations-contacts card p-0">
									<div class="row g-0">
										<h2 class="mb-1 card-title">
											{{ data.content.contact.header }}
										</h2>
										<h6
											class="card-subheader color-secondary"
										>
											{{ data.content.contact.subheader }}
										</h6>
										<div class="col-md-8">
											<div class="card-body p-0">
												<div class="card-adress">
													<span class="adress">
														{{
															data.content.contact
																.location.street
														}}
													</span>
													<span class="city">
														{{
															data.content.contact
																.location.zip
														}}
														{{
															data.content.contact
																.location.city
														}}
													</span>
												</div>
												<a
													:href="`mailto:${data.content.contact.location.email}`"
													title="E-Mail"
												>
													<span
														class="card-icon-email"
													>
														<font-awesome-icon
															:icon="[
																'fal',
																'envelope',
															]"
														/>
														{{
															data.content.contact
																.location.email
														}}
													</span>
												</a>
												<a
													:href="`tel:${data.content.contact
																.location.phone}`"
													title="Telefon"
												>
													<span
														class="card-icon-phone"
													>
														<font-awesome-icon
															:icon="[
																'fal',
																'phone',
															]"
														/>
														{{
															data.content.contact
																.location.phone
														}}
													</span>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- <div class="col-xs-12 col-lg-6 col-xl-6 col-xxl-6">
								<LocationGoogleMaps />
							</div>-->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
// import LocationGoogleMaps from '~/components/organisms/contacts/LocationGoogleMaps'

export default {
	name: 'ContactSubsidiary',
	// components: {
	// 	LocationGoogleMaps,
	// },
	props: {
		data: {
			type: Object,
			required: false,
			default: () => {},
		},
	},
	computed: {
		...mapState(['subsidiary']),
	},
}
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/common';
.locations-contacts {
	&.card {
		background-color: transparent !important;
		border: 0 !important;
		.card-title,
		.card-subheader {
			text-transform: none;
		}
		.card-subheader {
			@include responsive-type(2, 1);
			color: $primary-light;
			font-weight: $font-weight-bold;
		}

		.image--cover {
			width: 150px;
			height: 150px;
			border-radius: 50%;

			object-fit: cover;
			object-position: center right;
		}

		.card-body {
			display: grid;

			.card-person-name {
				@include responsive-type(3, 1);
				color: $primary;
				margin-bottom: rem(20);
			}

			.card-adress {
				span {
					display: block;
				}
				margin-bottom: rem(20);
			}

			.card-icon-phone,
			.card-icon-email,
			.card-icon-fax {
				color: $secondary;
				@include responsive-type(2, 1.8);

				.svg-inline--fa {
					color: $primary;
					margin-right: rem(10);
				}
			}
		}
	}
}
</style>
