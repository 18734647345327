<template>
	<div class="fp-section fp-auto-height headvisual-fullwidht">
		<div class="headvisual headvisual--has-image hero-image">
			<div class="headvisual--image-wrapper">
				<div class="ce-image">
					<img
						:src="data.media.image.publicUrl"
						:alt="data.media.image.properties.alternative"
					/>
				</div>
			</div>
			<div class="headvisual--content-wrapper has--circle">
				<div class="page--wrapper">
					<div
						class="headvisual--content px-lg-12"
						data-aos="fade-right"
						data-aos-duration="1000"
						data-aos-delay="1000"
					>
						<h1 class="headvisual--title">
							{{data.media.header}}
						</h1>
						<div class="mb-6 headvisual--content-subline">
							<html-parser
								:content="data.media.bodytext"
							/>
						</div>
					</div>
				</div>
				<PolygonShape
					class="headvisual-decor"
					data-aos="fade-right"
					data-aos-duration="900"
					data-aos-delay="900"
				/>
			</div>
		</div>
		<HeadvisualScrollIndicator />
	</div>
</template>

<script>
import HeadvisualScrollIndicator from '~/components/molecules/navigation/HeadvisualScrollIndicator';
import PolygonShape from '~/components/organisms/hero/PolygonShape';
export default {
	name: 'HeadvisualSubsidiary',
	components: {
		HeadvisualScrollIndicator,
		PolygonShape
	},
	 props: {
		data: {
			type: Object,
			required: false,
			default: () => {}
		}
	},
}
</script>
