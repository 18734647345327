// INFO With this method all images from the EXT:News are displayed in the frontend
export default {
	methods: {
		isCurrent (page) {
			return this.data.pagination.current === page;
		},
		getListImages (index) {
			return this.data.list[index]?.media
				|| [/* empty array */]
			;
		},
		getFirstImageSrc (index) {
			const images = this.getListImages(index);
			return images[0]?.images?.listViewFeaturedImage?.publicUrl
				|| images[0]?.images?.listViewImage?.publicUrl
				|| images[0]?.images?.defaultImage?.publicUrl
				|| null
			;
		},
		getFirstImageProperties (index) {
			const images = this.getListImages(index);
			return images[0]?.properties
				|| {/* empty object */}
			;
		}
	}
  };
