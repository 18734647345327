<template>
	<div class="swiper-wrapper">
		<swiper class="swiper" :options="swiperOption">

			<swiper-slide
				v-for="(singleNews, key) in data.list"
				:key="key"
			>
			<div class="news-content">

				<div class="news-content--item">
					<nav-link
						:to="singleNews.slug"
						tag="a"
					>
						<div class="news-content--item-img">
							<nuxt-img
								v-if="getFirstImageSrc(key)"
								:src="getFirstImageSrc(key)"
								:alt="getFirstImageProperties(key).alternative"
								format="webp"
								:sizes="{
									sm: '30vw',
									md: '50vw',
									lg: '50vw',
									xxl: '500px',
								}"
								preload
								class="lazyload"
							/>
						</div>

						<div
							v-if="singleNews.categories.length > 0"
							class="news-content--item-categories"
						>
							<span
								v-for="(category, index) in singleNews.categories"
								:key="index"
								class="categories-title"
							>
							<span>{{category.title}}</span>
							<span v-if="index+1 < singleNews.categories.length">, </span>
						</span>
						</div>

						<div class="news-content--item-body">
							<p
								v-if="singleNews.teaser"
								class="news-content--itemtext"
							>
								{{singleNews.teaser | truncateText(100)}}
							</p>
						</div>
					</nav-link>
				</div>

			</div>

			</swiper-slide>
		</swiper>
		<div class="swiper-pagination-round" slot="pagination"></div>
		<div class="swiper-button-prev" slot="button-prev"></div>
		<div class="swiper-button-next" slot="button-next"></div>
	</div>
</template>

<script>
	import NewsListMixins from './mixins/NewsListMixins';
	import objectFitImages from 'object-fit-images';
	import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
	export default {
		name: 'NewsSlider',
		mixins: [NewsListMixins],
		components: {
			Swiper,
			SwiperSlide
		},
		props: {
			data: {
				type: Object,
				required: false,
				default: () => {}
			}
		},
		data() {
			return {
				swiperOption: {
					pagination: {
						clickable: true,
						el: '.swiper-pagination-round',
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					},
					breakpoints: {
						640: {
							slidesPerView: 1,
							spaceBetween: 20,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 30,
						},
						1024: {
							slidesPerView: 2,
							spaceBetween: 45,
						},
					}
				},
			}
		}
	}
</script>
<style lang="scss">
.news-slider {
	.news-content--item-img {
		.lazyLoad:not(.isLoaded) {
			opacity: 1;
		}
	}
}
</style>
