<template>
    <nav class="teaser-menu"
		v-if="menu"
	>
        <ce-header v-bind="$props" />
        <TeaserMenuItem
			:menu="menu"
		/>
    </nav>
</template>

<script>
	import CeMenu_pages from '~typo3/components/content/elements/CeMenuPages.vue';
	import TeaserMenuItem from '~/components/molecules/navigation/TeaserMenuItem';
	export default {
		name: "TeaserMenu",
		extends: CeMenu_pages,
		components: {
			TeaserMenuItem
		},
	}
</script>
