<template>
	<div class="swiper-wrapper">
		<swiper
			class="swiper"
			:options="swiperOption"
		>

			<swiper-slide
				v-for="(item, key) in items"
				:key="key"
			>
				<div class="content-slider">

					<div class="content-slider--image-wrapper">
						<img
							v-if="getSliderImage(key)"
							:src="getSliderImageSrc(key)"
							:alt="getSliderProperties(key).alternative"
						/>
					</div>

					<div class="content-slider--content-wrapper">

						<div class="content-slider--content-inner">
							<div class="page--wrapper">

								<div class="content-slider--content">

									<h2 class="headvisual--title">
										{{item.content.header}}
									</h2>
									<div class="headvisual--content-subline">
										<html-parser
											:content="item.content.bodytext"
										/>
									</div>
								</div>

							</div>
						</div>

					</div>

				</div>
			</swiper-slide>
		</swiper>
		<div class="swiper-pagination-round" slot="pagination"></div>
		<div class="swiper-button-prev" slot="button-prev"></div>
		<div class="swiper-button-next" slot="button-next"></div>
	</div>
</template>

<script>
	import ButtonSecondary from '~/components/atoms/forms/buttons/ButtonSecondary';
	import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
	export default {
		name: 'ContentSlider',
		components: {
			Swiper,
			SwiperSlide,
			ButtonSecondary
		},
		props: {
			items: {
				type: Array,
				required: true,
				default: () => []
			}
		},
		data() {
			return {
				swiperOption: {
					slidesPerView: 1,
					spaceBetween: 50,
					pagination: {
						clickable: true,
						el: '.swiper-pagination-round',
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					},
				},
			}
		},
		methods: {
			getSliderImage (index) {
				return this.items[index]?.content.image
					|| [/* empty array */]
				;
			},
			getSliderImageSrc (index) {
				const image = this.getSliderImage(index);
				return image[0]?.publicUrl
					|| null
				;
			},
			getSliderProperties (index) {
				const image = this.getSliderImage(index);
				return image[0]?.properties
					|| {/* empty object */}
				;
			}
		}
	}
</script>

<style lang="scss">
	@import '~@/assets/scss/common';
	$content-slider--min-height: 200px !default;
	$content-slider--content-padding: 80px !default;
	$content-slider--content-padding-small: 20px !default;
	$content-slider--content-margin: 4% !default;
	$swiper-button-prev-icon-next-icon-color: color(primary, light) !default;
	$swiper-button-prev-icon-next-icon-color-hover: color(secondary, dark) !default;
	$swiper-button-prev-icon: arrow-circle-left !default;
	$swiper-button-next-icon: arrow-circle-right !default;
	$content-slider--bottom-wrapper--background: #fff !default;

	.layout-content_slider_smaller_center {
		@include media-breakpoint-up(xxl) {
			width: 66.66%;
			margin: 0 auto;
		}
	}

	.content-slider-container {
		position: relative;
	}

	.content-slider {
		position: relative;
		.page--wrapper {
			@include media-breakpoint-down(md) {
				margin-top: 0 !important;
			}
		}

		video,
		img {
			display: block;
			width: 100%;
			height: 100%;
			margin: 0 auto;

			object-fit: cover;
			font-family: 'object-fit: cover;';
		}

			.ce-gallery__col {
				margin: 0;
			}
	}

	@include media-breakpoint-up(md) {

		.content-slider {
			width: 200%;

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: stretch;

			overflow: hidden;

			blockquote {
				font-style: italic;
				font-weight: bold;
			}
		}

		.content-slider--image-wrapper,
		.content-slider--content-wrapper,
		.content-slider--bottom-wrapper {
			flex: 1 1 auto;
			width: 50%;
			min-height: 1px;
			height: 500px;
		}

		.content-slider--image-wrapper {

			figure,
			picture {
				display: block;
				height: 100%;
			}
		}

		.content-slider--content-wrapper {
			position: relative;
			top: 0;
			left: -50%;
		}

		.content-slider--bottom-wrapper {
			flex-grow: 0;
		}
	}

	.content-slider--content-inner {
		height: 100%;
		overflow: hidden;

		@include media-breakpoint-up(md) {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 100%;
				background: rgba(#fff, 0.8);
				background: linear-gradient(
					to right,
					rgba(#fff, 0.8),
					rgba(#fff, 0.8) 50%,
					rgba(#fff, 0) 100%
				);
			}
		}

		@include media-breakpoint-up(sm) {
			&:before {
				width: 66.666%;
			}
		}

		& > .page--wrapper {
			width: 100%;
			padding: 0;
		}
	}

	.content-slider--content {
		flex: 0 1 auto;
		position: relative;
		padding-top: 30px;
		padding-bottom: 10px;

		@include media-breakpoint-up(md) {
			padding-top:$content-slider--content-padding-small;
			padding-bottom: $content-slider--content-padding-small;
		}

		@include media-breakpoint-up(md) {
			max-width: 70%;
			padding: $content-slider--content-padding;
		}

		// @include media-breakpoint-up(lg) {
		// 	max-width: 50.666%;
		// }

		.button {
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
		}
	}

	.content-slider--bottom-wrapper {
		background: $content-slider--bottom-wrapper--background;
		padding: $content-slider--content-padding-small 0;

		@include media-breakpoint-up(xl) {
			padding: 30px 0;
		}
	}

	.swiper-wrapper {
		position: relative;

		.swiper-button-prev,
		.swiper-button-next {
			&:after {
				@include responsive-type(8, 1.0);
				color: $swiper-button-prev-icon-next-icon-color;
				border-radius: 100%;
			}

			&:hover  {
				&:after {
					color: $swiper-button-prev-icon-next-icon-color-hover;
				}
			}

			@include media-breakpoint-down(md) {
				display: none;
			}

			@include media-breakpoint-down(lg) {
				margin-top: -50px;
			}
		}

		.swiper-button-prev {
			margin-left: -$content-slider--content-margin;

			@media (max-width: 1700px) {
				display: none;
			}

			&:after {
				content: fa-content($fa-var-arrow-circle-left);
				font-family: 'Font Awesome 5 Pro';
				@extend %fa-icon;
			}
		}

		.swiper-button-next {
			margin-right: -$content-slider--content-margin;

			@media (max-width: 1500px) {
				display: none;
			}

			&:after {
				content: fa-content($fa-var-arrow-circle-right);
				font-family: 'Font Awesome 5 Pro';
				@extend %fa-icon;
			}
		}

		.swiper-pagination-round {
			position: absolute;
			bottom: -50px;
			left: 0;
			right: 0;
			display: flex;
			justify-content: center;

			.swiper-pagination-bullet {
				width: $content-slider--content-padding-small;
				height: $content-slider--content-padding-small;
				margin-right: 15px;
			}

			.swiper-pagination-bullet-active {
				background: $swiper-button-prev-icon-next-icon-color;
			}
		}
	}

	.has-spacing {
		.swiper-button-prev,
		.swiper-button-next {
			&:after {
				@include responsive-type(6, 1.0);
			}
		}
	}

	.swiper-wrapper .swiper-container {
		margin: 0 auto;

		@include media-breakpoint-up(lg) {
			margin-left: $content-slider--content-margin;
			margin-right: $content-slider--content-margin;
		}
	}
</style>
