<template>
	<div class="card-body">
		<div v-if="loadingStatus" class="loading-container">
			<div class="loading"></div>
			Daten werden geladen...
		</div>
		<div v-else-if="nearestLocation">
			<h2 class="mb-1 mt-1 card-title">Unsere Niederlassung</h2>
			<h6 class="card-subheader color-secondary">
				in {{ nearestLocation.ort }}
			</h6>
			<div class="d-flex flex-column gap-4 mb-4">
				<div class="card-adress">
					<span class="adress"> {{ nearestLocation.strasse }}, </span>
					<br />
					<span class="city">
						{{ nearestLocation.plz }}
						{{ nearestLocation.ort }}
					</span>
				</div>
				<div>
					<strong>E-Mail:</strong>
					<a :href="`mailto:${nearestLocation.email}`" title="E-Mail">
						<span class="card-icon-email">
							<font-awesome-icon :icon="['fal', 'envelope']" />
							{{ nearestLocation.email }}
						</span>
					</a>
				</div>
				<div>
					<strong>Telefon:</strong>
					<a :href="`tel:${nearestLocation.telefon}`" title="Telefon">
						<span class="card-icon-phone">
							<font-awesome-icon :icon="['fal', 'phone']" />
							{{ nearestLocation.telefon }}
						</span>
					</a>
				</div>
			</div>
			<!-- <div class="action-subsidiary">
				<nuxt-link
					class="btn btn-tertiary mt-2"
					:to="`/standort/${nearestLocation.ort}`"
					title="Zur Niederlassung"
				>
					Zur Niederlassung
				</nuxt-link>
			</div>
			-->
		</div>
		<div v-else class="text-center">
			<button class="btn btn-secondary mb-2" @click="requestLocationAndReload">
				Bitte aktivieren Sie Ihre Standortfreigabe um die Kontaktdaten
				zu sehen!
			</button>
			<p v-if="locationError">
				Es gab einen Fehler bei der Standortabfrage. <br />
				Bitte aktivieren Sie Ihre Standortfreigabe und versuchen Sie es
				erneut.
			</p>
		</div>
	</div>
</template>

<script>
import data from '../subsidiariesData.json'
const staticMarkers = data.values

export default {
	data() {
		return {
			nearestLocation: null,
			locationError: false,
			loadingStatus: true,
		}
	},
	computed: {
		coords() {
			return this.$geolocation.coords
		},
	},
	methods: {
		getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
			var R = 6371
			var dLat = this.deg2rad(lat2 - lat1)
			var dLon = this.deg2rad(lon2 - lon1)
			var a =
				Math.sin(dLat / 2) * Math.sin(dLat / 2) +
				Math.cos(this.deg2rad(lat1)) *
					Math.cos(this.deg2rad(lat2)) *
					Math.sin(dLon / 2) *
					Math.sin(dLon / 2)
			var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
			var d = R * c
			return d
		},
		deg2rad(deg) {
			return deg * (Math.PI / 180)
		},
		findNearestLocation() {
			let userLocation = this.coords
			let closestMarker = staticMarkers[0]
			let closestDistance = this.getDistanceFromLatLonInKm(
				userLocation.latitude,
				userLocation.longitude,
				parseFloat(closestMarker.position.lat),
				parseFloat(closestMarker.position.lng)
			)

			for (let i = 1; i < staticMarkers.length; i++) {
				let distance = this.getDistanceFromLatLonInKm(
					userLocation.latitude,
					userLocation.longitude,
					parseFloat(staticMarkers[i].position.lat),
					parseFloat(staticMarkers[i].position.lng)
				)
				if (distance < closestDistance) {
					closestMarker = staticMarkers[i]
					closestDistance = distance
				}
			}
			this.nearestLocation = closestMarker

			if (closestMarker && closestMarker.hasOwnProperty('ort')) {
				this.nearestLocation = closestMarker
				this.$store.commit('SET_ORT', closestMarker.ort)
			} else {
				console.error('Unable to find nearest location')
			}
		},
		async requestLocation() {
			try {
				await this.$geolocation.getCurrentPosition()
				this.findNearestLocation()
				this.loadingStatus = false
			} catch (error) {
				this.loadingStatus = false
				this.locationError = true
			}
		},
		async requestLocationAndReload() {
			await this.requestLocation();
			if (process.client && window.innerWidth <= 768) {
			window.location.reload();
			}
		},
	},
	mounted() {
		this.requestLocation()
		this.$geolocation.watch = true
		this.$geolocation
			.getCurrentPosition()
			.then(() => {
				this.findNearestLocation()
			})
			.catch((error) => {
				console.log('Error:', error)
			})
	},
	beforeDestroy() {
		this.$geolocation.watch = false
	},
	watch: {
		coords() {
			this.findNearestLocation()
		},
	},
}
</script>
<style lang="scss">
@import '~@/assets/scss/common';
.default-page.jobmarket {
	.page--wrapper {
		@include media-breakpoint-down(md) {
			margin-top: 0 !important;
			.page-title {
				margin-bottom: 0 !important;
			}
		}
	}
}
.card-subheader {
	@include responsive-type(2, 1);
	color: $primary-light;
	font-weight: $font-weight-bold;
}
.action-subsidiary {
	.btn {
		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}
}
</style>
