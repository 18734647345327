<template>
	<div class="google-maps-header pt-1">
		<h1 class="page-title"><span>DIREKTKONTAKT ZU IHRER NIEDERLASSUNG {{ header }}</span></h1>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	data() {
		return {
			header: '',
		};
	},
	computed: {
		...mapState(['ort'])
	},
	watch: {
		ort: function(newOrt) {
			this.header = newOrt;
		}
	}
};
</script>
