<template>
	<div>
		<news-single
			:data="data"
			v-if="isDetailPage"
		/>

		<div
			class="news-slider"
			v-if="isListPage && isSliderTemplate"
		>
			<news-slider
				:data="data"
				v-if="isSliderTemplate"
			/>
		</div>

		<div v-else>
			<news-list
				:data="data"
				v-if="isListPage"
			/>
		</div>

	</div>
</template>
<script>
import baseCe from "~typo3/components/content/mixins/baseCe";
import NewsList from "./NewsList";
import NewsSingle from "./NewsSingle";
import NewsSlider from "./NewsSlider";

export default {
  name: "CeNews_pi1",
  extends: baseCe,
	props: {
		data: {
			type: Object,
			required: false,
			default: () => {}
		}
	},
	components: {
		NewsList,
		NewsSingle,
		NewsSlider
	},
	computed: {
		isDetailPage() {
			return this.data && this.data.settings && this.data.settings.action === "detail";
		},
		isListPage() {
			return this.data && this.data.settings && this.data.settings.action === "list";
		},
		isSliderTemplate() {
			return this.data && this.data.settings && this.data.settings.templateLayout === "list_slider";
		}
	}
};
</script>

