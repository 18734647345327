var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"highlight-box--image fullwidth-container pt-5 pb-5"},[_c('div',{staticClass:"highlight-box fullwidth-container"},[_c('div',{staticClass:"page--wrapper"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"multi-columns"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-lg-6 col-xl-6 col-xxl-6 d-flex align-items-center"},[_c('div',{staticClass:"mb-3 locations-contacts card p-0"},[_c('div',{staticClass:"row g-0"},[_c('h2',{staticClass:"mb-1 card-title"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.data.content.contact.header)+"\n\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('h6',{staticClass:"card-subheader color-secondary"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.data.content.contact.subheader)+"\n\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"card-adress"},[_c('span',{staticClass:"adress"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.data.content.contact
															.location.street)+"\n\t\t\t\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('span',{staticClass:"city"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.data.content.contact
															.location.zip)+"\n\t\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.data.content.contact
															.location.city)+"\n\t\t\t\t\t\t\t\t\t\t\t\t")])]),_vm._v(" "),_c('a',{attrs:{"href":`mailto:${_vm.data.content.contact.location.email}`,"title":"E-Mail"}},[_c('span',{staticClass:"card-icon-email"},[_c('font-awesome-icon',{attrs:{"icon":[
															'fal',
															'envelope',
														]}}),_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.data.content.contact
															.location.email)+"\n\t\t\t\t\t\t\t\t\t\t\t\t")],1)]),_vm._v(" "),_c('a',{attrs:{"href":`tel:${_vm.data.content.contact
															.location.phone}`,"title":"Telefon"}},[_c('span',{staticClass:"card-icon-phone"},[_c('font-awesome-icon',{attrs:{"icon":[
															'fal',
															'phone',
														]}}),_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.data.content.contact
															.location.phone)+"\n\t\t\t\t\t\t\t\t\t\t\t\t")],1)])])])])])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }